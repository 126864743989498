import React, {useEffect, useState} from 'react'
import {Routes, Route, useNavigate} from 'react-router-dom'
import Home from "./containers/Home"

const App = () => {
     
    return (
        <Routes>
            <Route path="/*" element={<Home/>} />
        </Routes> 
    
    )
}

export default App
