import React, { useState, useRef, useEffect } from 'react';
import profile from '../images/profile.jpg'
import background_img from "../assets/imgs/home.jpeg"
import axios from 'axios';

const Home = () => {
  const [selectedServices, setSelectedServices] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const avatarRef = useRef(null); 
  const [showBookList, setShowBookList] = useState(false);

  // const services = [
  //   'Consultation',
  //   'Business Growth',
  //   'Instagram Sales',
  //   'Youtube Sales',
  //   'Email Campaign',
  //   'Kindle Sales',
  //   'Tiktok Sales',
  //   'Facebook Sales',
  //   'Mobile Apps',
  //   'Web Apps',
  // ]

  const services = [
    'Consultation',
    'Business Growth',
    'Marketing FREE Guide',
    'Sales FREE Guide'
  ]
  const handleServiceClick = (service) => {
    if (selectedServices.includes(service)) {
      setSelectedServices(selectedServices.filter((s) => s !== service));
    } else {
      setSelectedServices([...selectedServices, service]);
    }
  };

  useEffect(() => {
    // Add an event listener for the touchstart event
    const handleTouchStart = (e) => {
      const startY = e.touches[0].clientY;
      const threshold = 250; // Adjust the threshold as needed
      let pullDownStarted = false;

      const handleTouchMove = (moveEvent) => {
        const currentY = moveEvent.touches[0].clientY;
        const deltaY = currentY - startY;

        if (deltaY > threshold && !pullDownStarted) {
          pullDownStarted = true;
          window.location.reload(); // Refresh the page
        }
      };

      const handleTouchEnd = () => {
        window.removeEventListener('touchmove', handleTouchMove);
        window.removeEventListener('touchend', handleTouchEnd);
      };

      window.addEventListener('touchmove', handleTouchMove);
      window.addEventListener('touchend', handleTouchEnd);
    };

    window.addEventListener('touchstart', handleTouchStart);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

     // Validate email using a regular expression
     const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
     if (!emailRegex.test(email)) {
       setIsValidEmail(false);
       return;
     }
 
     setIsValidEmail(true);

    // console.log('Selected Services:', selectedServices.toString());
    // console.log('Name:', name);
    // console.log('Email:', email);
    // console.log('Message:', message);

    const emailData = {
      name,
      email,
      message: "Services: " + selectedServices.join(", ") + "\r\n" + message
    }

    // console.log(emailData)

     try {
      const response = await fetch('https://theadediran.com/.inc/send-email.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(emailData),
        });

        if (response.ok) {
          setShowDialog(true);
          setName('');
          setEmail('');
          setMessage('');
        } else {
          alert('Email not sent. Try again');
        }
      } catch (error) {
        // console.error('Error:', error);
        alert('Email not sent. Try again.');
      }

    // try {
    //   const response = await axios.post('https://theadediran.com/.inc/send-email.php', emailData, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //   });
    
    //   if (response.status === 200) {
    //     setShowDialog(true);
    //   } else {
    //     console.log(response.status)
    //     alert('Email not sent for some reasons. Try again');
    //   }
    // } catch (error) {
    //   // Handle the error as needed
    //   // console.error('Error:', error);
    // }
    
    
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const scrollToAvatar = () => {
    // Scroll to the avatar element when focusing on the input
    if (avatarRef.current) {
      avatarRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const toggleBookList = () => {
    setShowBookList(!showBookList); // Toggle the book list popup
  };

  // rounded-lg
  return (
    <div className="h-screen flex items-center justify-center" 
    style={{
      backgroundImage: `url(${background_img})`
      }}>
      <div className="bg-black w-full bg-opacity-70 p-8 shadow-lg h-screen overflow-y-scroll no-scrollbar">
      {/* <div className='h-5' ref={avatarRef}></div> */}

      {/* <div className='w-1/2 mx-auto'> */}
        <div className="text-center mb-5">
          <img
            src={profile}
            alt="Ifeoluwa Adediran"
            style={{width: '150px'}}
            className="rounded-full mx-auto mb-4"
          />
          <h1 className="text-2xl font-bold text-gray-100">Ifeoluwa Adediran</h1>
          <p className="text-gray-200">Helping you 10x your online business 💸</p>
        </div>

        <div className="mb-4 mt-2">
          {/* Button to toggle the book list */}
          <button
            onClick={toggleBookList}
            className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg focus:outline-none w-full"
          >
            Amazon Best Selling Leadership Books ~ Giveaway
          </button>

          {/* Display the book list popup when showBookList is true */}
          {showBookList && (
            <div className="mt-4 p-4 bg-white border rounded-lg shadow-lg">
              <h2 className="text-xl font-semibold mb-2">Pick your favorites</h2>
              <ul className='list-disc pl-4'>
                <li>How To Make Social Media Work For You Like A Rockstar by Ifeoluwa Adediran</li>
                <li>The 12-Weeks Year by Brian P. Moran and Michael Lennington</li>
                <li>Win the Day by Mark Batterson</li>
                <li>Life Force by Tony Robbins</li>
                <li>How I Turned $1000 into a Billion Dollars Business by Barbara Corcoran </li>
                <li>The 6 Types of Working Genius by Patrick Lencioni</li>
                {/* Add more books as needed */}
              </ul>
            </div>
          )}
        </div>

        <div className="mt-8">
          <h2 className="text-lg font-semibold mb-4 text-white">Choose the best services for you</h2>
          <div className="grid grid-cols-2 gap-4">
            {services.map((service, index) => (
              <button
                key={index}
                className={`${
                  selectedServices.includes(service)
                    ? 'bg-green-600 hover:bg-green-700'
                    : 'bg-blue-500 hover:bg-blue-600'
                } 
                text-white text-xs md:text-lg py-2 px-4 rounded-lg focus:outline-none`}
                onClick={() => handleServiceClick(service)}
              >
                {service}
              </button>
            ))}
          </div>
        </div>

        <div className="mt-8 h-4/6">
          <h2 className="text-lg font-semibold mb-4 text-white">Contact Me</h2>
          <form onSubmit={handleSubmit} onFocus={scrollToAvatar}>
            {/* <label htmlFor="name" className="block text-gray-700 font-bold">
                Name
            </label> */}
            <div className="mb-4">
              <input
                type="text"
                id="name"
                placeholder='Name'
                className="w-full border rounded-md py-2 px-3 focus:outline-none focus:none focus:border-blue-300"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            {/* <label htmlFor="email" className="block text-gray-700 font-bold">
                Email
            </label> */}
            <div className="mb-4">
               <input
                type="email"
                id="email"
                placeholder='Email'
                className={`w-full border rounded-md py-2 px-3 focus:outline-none focus:none focus:border-blue-300 ${
                  !isValidEmail ? 'border-red-500' : ''
                }`}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setIsValidEmail(true); // Reset validation when the user types
                }}
                required
              />
            </div>
            {/* <label htmlFor="message" className="block text-gray-700 font-bold">
                How can I help you?
              </label> */}
            <div className="mb-4">
              <textarea
                id="message"
                rows="4"
                placeholder='How can I help you today? Be specific with your request.'
                className="w-full border resize-none rounded-md py-2 px-3 focus:outline-none focus:none focus:border-blue-300"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg focus:outline-none"
            >
              Send Message
            </button>
          </form>
        </div>


        {/* </div> */}
      </div>

      {showDialog && (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="bg-white p-4 rounded-lg shadow-lg text-center">
          <p className="text-2xl font-bold mb-4">Thank You!</p>
          <p className="text-gray-700">Expect to receive a follow-up soon.</p>
          <button
            onClick={closeDialog}
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg mt-4 focus:outline-none"
          >
            Close
          </button>
        </div>
      </div>
    )}
    </div>
  );
};

export default Home;
