import React from 'react'
// import ReactDOM from 'react-dom'
import ReactDOM from "react-dom/client";
import {BrowserRouter as Router, HashRouter} from 'react-router-dom'
import App from './App'
import './index.css'
import ErrorBoundary from './ErrorBoundary';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ErrorBoundary>
        <HashRouter>
            <App />
        </HashRouter>
    </ErrorBoundary>
);