import React, { Component } from 'react';
import './ErrorBoundary.css';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    console.error('Error caught:', error, info);
    this.setState({ hasError: true });
  }

  reloadPage = () => {
    // window.location.reload();
    const lc_splash = 'com.theadedira.visited'
    localStorage.removeItem(lc_splash);
    if (typeof window.caches !== 'undefined') {
      // Your code that uses the window object
      window.caches.keys().then((names) => {
        names.forEach((name) => {
              window.caches.delete(name);
          });
      });
    }
    
    window.location.href = window.location.origin;
  }

  render() {
    if (this.state.hasError) {
      // You can customize the error UI here
      return (
        <div className="splash-screen">
          <h1 className="">Page is not available at this time.</h1>
          <button className="bg-blue-300 text-white rounded p-2 mt-5" onClick={this.reloadPage}>Try again</button>
      </div>
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
